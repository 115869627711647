@media only screen and (min-width:320px) and (max-width:1024px) {
    .wanderlusty-imagemount-container {
        display : flex;

        /*flex-direction : row-reverse;*/
        flex-direction : column;
        margin-bottom : 98px;
        margin-left : auto;
        margin-right : auto;
        margin-top : 98px;

        overflow : hidden;
        width : 87%;

        //.imagemount-image {
        //    width : 100%;

            img {
                max-width : 100%;
            }
        //}

        .imagemount-details {
            width : 100%;
            h3 {
                font-size : 1.5em;
                letter-spacing : 0.16em;
                margin-bottom : 8px;
                margin-left : auto;
                margin-right : auto;
                text-align : center;
            }

            .imagemount-film-details {
                margin-top : 0px;
                text-align : center;

            }
        }
    }

    .wanderlusty-imagemount-container.right-img-left-text {
        flex-direction : column;
    }

    .wanderlusty-imagemount-container.left-img-right-text {
        flex-direction : column;
    }

    .wanderlusty-imagemount-container.top-img-lower-text {
        flex-direction : column;

        //.imagemount-image {
            //width : 100%;

            img {
                max-width : 100%;
            }
        //}
    }
}