@import './components/header/Header-desktop.scss';
@import './components/header/Header-mobile.scss';
@import './components/footer/Footer.scss';
@import './components/album/Album-desktop.scss';
@import './components/album/Album-mobile.scss';
@import './components/gallery/Gallery-desktop.scss';
@import './components/text-content/TextContent-desktop.scss';

@import './components/image-mount/ImageMount-desktop.scss';
@import './components/image-mount/ImageMount-mobile.scss';
@import './components/image-overlay/ImageOverlay-desktop.scss';

@import './components/loading/Loading.scss';


$text-default : #402c2c;
$text-secondary : #402c2c;
/*3a3a3a*/
$header-candidate1 : #a65866;
$header-text-candidate1 : #f2d8d5;


body {
    background-color : #f9f9f9;
    color : $text-default;
}

a,a:visited,a:hover, a:active {
    color : $text-default;
    text-decoration : none;
}