.wanderlusty-text-content {
    
    margin-left : auto;
    margin-right : auto;
    padding : 18px;
    width : 87%;
    
    p {
        font-size : 1.18em;
        margin : 0;
        padding : 0;
    }
}