$animation-speed : 0.32s;

.wanderlusty-header {
    position : fixed;
    top : 0;
    z-index : 5000;
}



@media only screen and (min-width:1025px) {
    .wanderlusty-header {    
        overflow : hidden;
        user-select : none;
        width : 100%;

        .expansion-container {
            background-color : #f9f9f9;
            border-bottom-color: #4a4a4a;
            border-bottom-style: solid;
            border-bottom-width : 1px;
            transition : height $animation-speed ease-in 0s;

            h1 {

                display : block;
                font-family: 'Garda';
                text-align : center;
                transition : color $animation-speed ease-in 0s, transform $animation-speed ease-in 0s, font-size $animation-speed ease-in 0s;
            }
        }

        .expansion-container.expanded {

            
            background-image : url('resources/img/border-bottom.jpg');
            background-repeat: no-repeat;
            background-position : center;
            background-size : cover;

            height : 12em;

            padding-top : 24px;

            h1 {
                color : #f9f9f9;
                font-size : 4.2em;
                letter-spacing : 0.2em;
                margin : 0; 
                margin-right : -0.2em;                   
            }

            h3 {
                color : #f9f9f9;
                font-family: 'Garda';
                letter-spacing : 0.2em;
                margin-bottom : 10px;
                margin-top : 28px; 
                text-align : center;
            }
            
            
        }

        .expansion-container.collapsed {
            height : 4em;
            padding-top : 24px;

            h1 {                
                font-size : 2em;
                letter-spacing : 0.2em;
                margin : 0; 
                margin-right : -0.2em; 
                
            }

            h3 {
                display : none;
            }
        }
    }
}