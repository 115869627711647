@media only screen and (min-width:1025px) {
    .image-mount-spacing {
        margin-top : 1800px;
    }
    
    .wanderlusty-imagemount-container {
        display : flex;

        flex-direction : column;
        margin-bottom : 200px;
        margin-left : auto;
        margin-right : auto;
        margin-top : 200px;

        /*128*/

        overflow : hidden;
        width : 87%;
        img {
            cursor : zoom-in;
            margin : 0 auto;
            max-height : 85vh;
            max-width : 70%;

        }

        .imagemount-details {
            padding : 18px;
            width : 30%;
            h3 {
                font-size : 1.5em;
                letter-spacing : 0.16em;
                margin-bottom : 8px;
                margin-left : auto;
                margin-right : auto;
            }

            .imagemount-film-details {
                margin-top : 0px;
            }
        }
    }

    /*.wanderlusty-imagemount-container:first {
        margin-top : 12800px;
    }*/

    .wanderlusty-imagemount-container.left-img-right-text, 
    .wanderlusty-imagemount-container.right-img-left-text{
        .imagemount-details {
            padding-top : 0;

            h3 {
                margin-top : 0;
            }
        }
    }

    .wanderlusty-imagemount-container.right-img-left-text {
        flex-direction : row-reverse;

        .imagemount-details {
            text-align : justify;
        }

        .imagemount-details h3 {
            text-align : left;
        }

        .imagemount-film-details {
            text-align : left;
        }
    }

    .wanderlusty-imagemount-container.left-img-right-text {
        flex-direction : row;

        .imagemount-details {
            text-align : justify;
        }

        .imagemount-details h3 {
            text-align : left;
        }

        .imagemount-film-details {
            text-align : left;
        }
    }

    .wanderlusty-imagemount-container.top-img-lower-text {
        flex-direction : column;
        
        img {
            margin : 0 auto;
            max-width : 100%;
        }

        .imagemount-details {
            padding : 18px;
            width : 100%;
            h3 {
                font-size : 1.5em;
                letter-spacing : 0.16em;
                margin-left : auto;
                margin-right : auto;
                text-align : center;
            }

            p {

            }
        }

        .imagemount-film-details {
            text-align : center;
        }
    }
}