.wanderlusty-gallery-container {
    width : 100%;

    .wanderlusty-gallery-title {
        
        width : 100%;
        
        h2 {
            font-family : 'Garda';
            font-size : 2.4em;
            letter-spacing: 0.22em;
            text-align : center;
            width : 100%;
        }
    }
}