@media only screen and (min-width:320px) and (max-width:1024px) {

    .wanderlusty-album-container {
        background-color : #c9c9c9;
        cursor : pointer;
        height : 40vh;
        margin-bottom : 0px;
        margin-top : 0px; 
        overflow : hidden;
        position : relative;
        user-select : none;
        width : 100%;
    }

    .wanderlusty-bgi-filter {
        background-position : center;
        background-repeat : no-repeat;
        background-size : cover;    
        filter : blur(0px);
        height : 40vh;
        left : 0;
        position : absolute;
        top : 0;
        transform : scale(1);
        transition-property : filter, transform; 
        transition-duration : 0.27s;
        transition-timing-function : ease-in;
        transition-delay : 0s;
        width : 100%;
        z-index : 500;
    }

    .wanderlusty-album-container:hover  .wanderlusty-bgi-filter {
        filter : blur(14px);
        transform : scale(1.12);
    }

    .wanderlusty-opacity-filter {
        background-color : #3a3a3a;
        height : 100%;
        opacity : 0.4;
        position : absolute;
        width : 100%;
        z-index : 1000;
    }

    .wanderlusty-album-name {
        
        display : grid;
        height : 100%;
        place-items : center center;
        position : relative;
        width : 100%;
        z-index: 1500;

        h1 {
            color : #f9f9f9;
            font-family : 'Garda';
            line-height : 2.4em;
            letter-spacing : 1.4rem;
            margin-right : -1.4em; 
            text-align : center;
            width : 90%;
        }
    }


    /*Device specific modifications*/
    /*Phone*/
    @media only screen and (min-width:320px) and (max-width:760px) {
        .wanderlusty-album-name {
            h1 {
             font-size : 1.6em;
            }
             
         }
    }
    /*Tablet*/
    @media only screen and (min-width:761px) and (max-width:1024px) {
        .wanderlusty-album-name {
           h1 {
            font-size : 2.6em;
           }
            
        }
        

    }
}