.loading-container {
    background-color : #ff0000;
    display : block;
    height : 150px;
    position : relative;
    width : 100%;
    

    #loading-text {
        display : flex;
        position : absolute;
        left : 50%;
        right: 50%;

        h3 {

            display : inline-block;
            font-size : 3em;
        }

        .dot {
            display : inline-block;
            font-size : 3em;
            letter-spacing : -11px;
            margin : 0;
            animation-duration : 1.8s;
            animation-name : dot-bounce;
            animation-iteration-count: infinite;
        }

        .dot:nth-child(1) {
            animation-delay : 0s;
        }
        .dot:nth-child(2) {
            animation-delay : 0.1s;
        }  
        .dot:nth-child(3) {
            animation-delay : 0.2s;
        } 
        .dot:nth-child(4) {
            animation-delay : 0.3s;
        }  
        .dot:nth-child(5) {
            animation-delay : 0.4s;
        }
        .dot:nth-child(6) {
            animation-delay : 0.5s;
        }  
        .dot:nth-child(7) {
            animation-delay : 0.6s;
        } 
        .dot:nth-child(8) {
            animation-delay : 0.7s;
        }  
        .dot:nth-child(9) {
            animation-delay : 0.8s;
        } 
        .dot:nth-child(10) {
            animation-delay : 0.9s;
        }  
        .dot:nth-child(11) {
            animation-delay : 1.0s;
        } 
        .dot:nth-child(12) {
            animation-delay : 1.1s;
        }  
        .dot:nth-child(13) {
            animation-delay : 1.2s;
        } 
        .dot:nth-child(14) {
            animation-delay : 1.3s;
        }  
        .dot:nth-child(15) {
            animation-delay : 1.4s;
        } 
        .dot:nth-child(16) {
            animation-delay : 1.5s;
        }  
        .dot:nth-child(17) {
            animation-delay : 1.6s;
        } 
        .dot:nth-child(18) {
            animation-delay : 1.7s;
        }  
        .dot:nth-child(19) {
            animation-delay : 1.8s;
        }
        .dot:nth-child(20) {
            animation-delay : 1.9s;
        }
    }
    

    @keyframes dot-bounce {
        from {
            transform : translateY(0px); 
        }

        50% {
            transform : translateY(-22px);
        }

        to {
            transform : translateY(0px);
        }
    }
}

