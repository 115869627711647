@media only screen and (min-width:1025px) {

    .wanderlusty-album-container {
        background-color : #c9c9c9;
        cursor : pointer;
        height : 40vh;
        margin-bottom : 168px;
        margin-top : 45px; 
        overflow : hidden;
        position : relative;
        user-select : none;
        width : 100%;
    }

    .wanderlusty-bgi-filter {
        background-position : center;
        background-repeat : no-repeat;
        background-size : cover;    
        filter : blur(0px);
        height : 40vh;
        left : 0;
        position : absolute;
        top : 0;
        transform : scale(1);
        transition-property : filter, transform; 
        transition-duration : 0.27s;
        transition-timing-function : ease-in;
        transition-delay : 0s;
        width : 100%;
    }

    .wanderlusty-album-container:hover  .wanderlusty-bgi-filter {
        filter : blur(14px);
        transform : scale(1.12);
    }

    .wanderlusty-opacity-filter {
        background-color : #3a3a3a;
        height : 100%;
        opacity : 0.35;
        position : absolute;
        width : 100%;
        z-index : 1000;
    }

    .wanderlusty-album-name {
        
        position : relative;
        top : 50%;
        transform : translateY(50%);
        z-index: 1000;
        
        h1 {
            color : #f9f9f9;
            display : block;
            font-family : 'Garda';
            font-size : 2.6em; 
            letter-spacing : 1.4rem; 
            margin-left : 68px;
        }
    }
}