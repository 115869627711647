@import "All.scss";

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}


* {
  font-family: 'Galaxie Polaris';
}


@media only screen and (min-width:1025px) {
  .top-spacer {
    display : block;
    height : 300px;
    width : 100%;
  }
}


@media only screen and (min-width:320px) and (max-width:1024px) {
  .top-spacer {
    display : block;
    height : 150px;
    width : 100%;
  }
}

/*Correcting for very large screens*/
@media only screen and (min-width:1025px) {
  .wanderlusty-content {
    margin-left : auto;
    margin-right : auto;
    max-width : 70%;
  }
}

/*Fonts*/
@font-face {
  font-family: 'Galaxie Polaris';
  src: url(resources/fonts/Polaris-Book.woff) format("opentype")
}

@font-face {
  font-family: 'Garda';
  src: url(resources/fonts/Garda-TitlingOne.woff) format("opentype")
}