.wanderlusty-image-overlay {
    height : 100vh;
    left : 0;
    position : fixed;
    top : 0;
    user-select : none;
    width : 100vw;
    z-index : 5000;

    .image-overlay-background {
        
        background-color : #1c1c1c;
        height : 100vh;
        left : 0;
        /*opacity : 0.98;*/
        opacity : 1;

        position : fixed;
        top : 0;
        width : 100vw;
        z-index : 5250;
    }

    .image-overlay-content {
        display : flex;
        flex-direction : row;
        height : 100vh;
        left : 0;
        position : fixed;
        top : 0;
        width : 100vw;
        z-index : 5750;
        
        .image-overlay-left, .image-overlay-right {
            display : grid;
            height : 100vh;
            place-items : center center;
            position : relative;
            width : 6%;
    
            p {
                color : #6c6c6c;
                cursor : pointer;
                font-size : 4.4em;
                margin : 0;
                text-align : center;
                transition : color 0.2s ease-in 0s;
                user-select : none;
            }
    
            p:hover {
                color : #9c9c9c;
            }

            .overlay-exit {
                font-size : 2.5em; 
                left : 0;
                position : absolute;
                top : 0.4em;

            }
        }

        
    
        .image-overlay-main-division {
            display : grid;
            place-items : center center;
            width : 88%;

            .image-overlay-image-container {
                position : relative;

                img {
                    max-width : 100%;
                    max-height : 100vh;
                    position : relative;
                }
    
                .image-overlay-image-detail {
                    background-color : #1c1c1c;
                    bottom : 0;
                    color : #c9c9c9;
                    opacity : 0;
                    padding : 8px;
                    position : absolute;
                    transition : opacity 0.28s ease-in 0s;
                    max-width : 100%;
                    min-width : 100%;
                    h3 {
                        .overlay-film-info {
                            font-size : 0.7em;
                        }
                    }
    
                    p {
                        
                    }
                }  
            }


               
        }

        .image-overlay-image-container:hover {
            .image-overlay-image-detail {
                opacity : 0.9;
            }
        }
    }

    
}