@media only screen and (min-width:320px) and (max-width:1024px) {
    .wanderlusty-header {
        
        
        color : #402c2c;
        margin-bottom : 58px;
        overflow : hidden;
        user-select : none;
        width : 100%;

        /*.wanderlusty-header-border {
            background-color : #f9f9f9;
            background-repeat : repeat-x;
            background-size: cover;
            display : block;
            height : 4.8em;
            width : 100%;
        }

        .wanderlusty-header-top-border {
            background-image : url('resources/img/border-top.JPG');
            background-position : bottom;
        }*/
        .expansion-container {

            background-color : #f9f9f9;
            border-bottom-color: #4a4a4a;
            border-bottom-style: solid;
            border-bottom-width : 1px;
            padding : 18px;
            transition : height $animation-speed ease-in 0s;

            h1 {

                display : block;
                font-family: 'Garda';
                letter-spacing : 0.2em;
                margin : 0; 
                text-align : center;
                transition : color $animation-speed ease-in 0s, transform $animation-speed ease-in 0s, font-size $animation-speed ease-in 0s;
            }

            
            h3 {
                font-family: 'Garda';
                letter-spacing : 0.2em;
                margin : 0;
                margin-top : 28px; 
                text-align : center;
            }
        }

        
    }

    /*Device specific modifications*/
    /*Phone*/
    @media only screen and (min-width:320px) and (max-width:760px) {
       
        .expansion-container.expanded {
            
            background-image : url('resources/img/border-bottom.jpg');
            background-repeat: no-repeat;
            background-position : center;
            background-size : cover;
            height : 5em;
            padding-top : 24px;

            h1 {
                color : #f9f9f9;
                font-size : 1.2em;
                letter-spacing : 0.2em;
                margin : 0; 
                margin-right : -0.2em;                   
            }

            h3 {
                color : #f9f9f9;
                font-size: 0.5em;
                letter-spacing : 0.2em;
                margin-bottom : 10px;
                margin-top : 28px; 
                text-align : center;
            }
            
        }

        .expansion-container.collapsed {
            height : 1.8em;
            padding-top : 24px;

            h1 {         
                font-size : 1.2em;
                letter-spacing : 0.2em;
                margin : 0; 
                margin-right : -0.2em; 
                
            }

            h3 {
                display : none;
            }
        }
    }


    /*Tablet*/
    @media only screen and (min-width:761px) and (max-width:1024px) {
        
        .expansion-container.expanded {
            background-image : url('resources/img/border-bottom.jpg');
            background-repeat: no-repeat;
            background-position : center;
            background-size : cover;
            height : 7em;
            padding-top : 24px;

            h1 {
                color : #f9f9f9;
                font-size : 2.2em;
                letter-spacing : 0.2em;
                margin : 0; 
                margin-right : -0.2em;                   
            }

            h3 {
                color : #f9f9f9;
                font-size: 1.1em;
                letter-spacing : 0.2em;
                margin-bottom : 10px;
                margin-top : 28px; 
                text-align : center;
            }
            
        }

        .expansion-container.collapsed {
            height : 3.2em;
            padding-top : 24px;

            h1 {         
                font-size : 2em;
                letter-spacing : 0.2em;
                margin : 0; 
                margin-right : -0.2em; 
                
            }

            h3 {
                display : none;
            }
        }
    }
}