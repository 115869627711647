.wanderlusty-footer {
    border-top-color : #3a3a3a;
    border-top-style: dotted;
    border-top-width: 18px;

    height : 38px;
    margin-bottom : 38px;
    margin-left : auto;
    margin-right : auto;
    margin-top : 58px;
    padding-top : 38px;
    position : relative;
    width : 15%;
}

.wanderlusty-footer img {
    cursor : pointer;
    display : block;
    margin-left : auto;
    margin-right : auto;
    transform : rotate(45deg);
    user-select : none;
    width : 38px;
}

.wanderlusty-footer p {
    font-size : 0.75em;
    text-align : center;
}